<template lang="pug">
  div(v-if="navigatorLanguage === 'es'")
    div.d-flex.fill-height(style="width: 100%; column-gap: 20px;")
      div.d-flex.flex-column.justify-start
        div(style="font-size: 14px;")
          p(style="font-size: 16px; color: #F93549; font-weight: 600; margin-bottom: 2em;") CÓMO SE CALCULA
          div.px-5
            p(style="margin-bottom: 2em;") #[strong Paso 1]- GastroPricing está integrado con GastroRanking, y toma de este último indicar de reputación el Índice de Calidad del restaurante/marca propio y de la competencia. Calcula la media de todos y busca el ratio /relación entre el índice de calidad medio y el cada restaurante /marca.
            p De esta forma se calcula el  Índice de Evaluación de Calidad:
            div.px-5.py-3.mb-5
              div.px-5.py-3.bg-white.d-flex.justify-center.align-center(style="border: solid 1px #F93549; border-radius: .8em;") IEC= Índice de calidad restaurante/ Media de los índices de calidad
            p(style="margin-bottom: 2em;") #[strong Paso 2]- Se calcula el  la media del Ticket medio Estándar de todos los competidores, y este se multiplica por el IEC de cada restaurante obteniendo así el IPJ  del restaurante propio y de los competidores.



  div(v-else-if="navigatorLanguage === 'en'")
    div.d-flex.fill-height(style="width: 100%; column-gap: 20px;")
      div.d-flex.flex-column.justify-start
        div(style="font-size: 14px;")
          p(style="font-size: 16px; color: #F93549; font-weight: 600; margin-bottom: 2em;") HOW IT IS CALCULATED
          div.px-5.pt-8
            p(style="margin-bottom: 2em;") #[strong Step 1]- Calculates the average of the GastroRanking Quality Index for the brand and competitors. Then calculates the relationship between the average quality index and each brand, getting then the Quality Evaluation Index
            div.px-5.py-3.mb-5
              div.px-5.py-3.bg-white.d-flex.justify-center.align-center(style="border: solid 1px #F93549; border-radius: .8em;") QEI = Restaurant quality index / Average quality indexes
            p(style="margin-bottom: 2em;") #[strong Step 2]- The average of the Standard Ticket of all competitors is calculated. What is multiplied by the QEI of each restaurant, obtaining the API of the own restaurant and the competitors.
</template>

<script>
export default {
  name: "Card_2",
  components: {}
}
</script>

<style scoped>

</style>
